<template>
    <div class="centered"> 
        <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/coalManage' }">项目管理</el-breadcrumb-item>
            <el-breadcrumb-item>增加项目</el-breadcrumb-item>
        </el-breadcrumb>
    <el-divider class="divi"></el-divider>
    <!-- 添加项目的表单 -->

    <!-- 内容主体区域 -->
    <el-form ref="addFormRef" :model="addForm" label-width="110px">
        <el-form-item label="新项目名称" prop="coalName">
            <el-input style="width: 256px;" v-model="addForm.coalName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-row>
            <el-col :span="10">
                <el-form-item label="接入系统（选择原矿区已有系统）" size="mini">
                    <el-checkbox-group v-model="addForm.systemIdList" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="system in systemList" :key="system" :label="system">{{ system }}</el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item>
            <el-button type="primary">增加系统</el-button>
        </el-form-item>

        <el-form-item label="新项目地址" prop="coalAddress">
            <el-input style="width: 256px;" v-model="addForm.coalAddress" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="接入时间">
            <el-input class="small-input" placeholder="请选择日期" suffix-icon="el-icon-date" v-model="time1">
            </el-input>
            <el-input class="small-input" placeholder="请选择时间" suffix-icon="el-icon-timer" v-model="time2">
            </el-input>
        </el-form-item>
        <el-form-item label="当前操作员" prop="currOperator">
            <el-input class="small-input" v-model="addForm.currOperator" placeholder="管理员user"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark">
            <el-input type="textarea" :rows="2" style="width: 286px" v-model="addForm.remark" placeholder="请输入200字以内的备注信息"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" >完成</el-button>
  </span>

    </div>
</template>

<script>
    const systemOptions = ['主运输系统', '通风系统', '安全监测', '矿压监测', '主排水系统', '压风系统', '束管监测', '瓦斯抽放'];
    export default {

        name: "addCoal",
        data() {
            return {
                systemList: systemOptions,
                // 添加项目的表单数据
                addForm: {
                    id: 0,
                    coalName: '',
                    coalAddress: '',
                    currOperator: '',
                    remark: '',
                    systemIdList: [],
                },
                time1: '',
                time2: '',
                checkAll: false,
                isIndeterminate: true
            }
        },
        methods: {
            handleCheckAllChange(val) {
                this.addForm.systemIdList = val ? systemOptions : [];
                this.isIndeterminate = false;
            },
            handleCheckedCitiesChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.systemList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.systemList.length;
            }
        }
    }
</script>

<style scoped>

</style>